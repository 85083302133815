// In src/Page.js

import React, { useEffect, useContext } from 'react'
import BodyWrapper from '../components/Styled/BodyWrapper'
import { Container, Row } from '../components/Styled/Grid.js'
import { graphql } from 'gatsby'
import { Box, ResponsiveContext, Image } from 'grommet'
import Imgix, { Picture, Source } from 'react-imgix'
import {
  Heading,
  Bullet,
  SubSubTitle,
  SectionTitle,
} from '../components/Styled/Type'
import { colors } from '../components/Styled/Colors'
import Layout from '../components/Global/Layout'
import Seo from '../components/Global/Seo'

const BlogComponent = ({
  data: {
    prismicBlog: {
      data: { blog_image, blog_title, subtitle, banner_image, body },
    },
  },
  data,
}) => {
  const size = useContext(ResponsiveContext)

  return (
    <Layout>
      <Seo />
      <BodyWrapper>
        <Box
          style={{ position: 'relative', overflow: 'hidden' }}
          height={size !== 'small' ? '40vh' : '70vh'}
          fill={'horizontal'}
          direction={size !== 'small' ? 'row' : 'column-reverse'}
        >
          <Image fill={'horizontal'} fit="cover" src={blog_image.url} />
        </Box>

        <Box width={{ max: 'xlarge' }}>
          <Container>
            <Row>
              <Box align="start" justify="start">
                <Bullet color={colors.purple} margin={'none'}>
                  {subtitle.text}
                </Bullet>
                <Heading>{blog_title.text}</Heading>
              </Box>

              {body.map(({ primary }, key) => (
                <div
                  key={key}
                  className={'blog_content'}
                  dangerouslySetInnerHTML={{ __html: primary.text.html }}
                />
              ))}
            </Row>
          </Container>
        </Box>
      </BodyWrapper>
    </Layout>
  )
}

export const query = graphql`
  query service($id: String!) {
    prismicBlog(id: { eq: $id }) {
      data {
        blog_image {
          url
        }
        subtitle {
          text
        }
        blog_title {
          text
        }
        body {
          ... on PrismicBlogBodyText {
            primary {
              text {
                html
              }
            }
            slice_label
            slice_type
          }
        }
      }
    }
  }
`

export default BlogComponent
